<template>
  <v-container class="credential-design">
    <v-row>
      <v-col cols="12" class="pt-7 pb-0 breadcrumbs">
        <div @click="$router.push('/Dashboard')">
          <p style="margin: 2px">
            {{ $t('dashboard.title') }}
          </p>
        </div>
        <div
          @click="currentPage && $router.go(-1)"
          :class="{ active: !currentPage }"
        >
          <IconArrowCreadCrumbs style="margin: 0px 6px 2px 6px" />
          <p style="margin: 2px 6px 2px 2px">
            {{ $t('credentialDesign.title') }}
          </p>
        </div>
        <div v-if="currentPage" :class="{ active: currentPage }">
          <IconArrowCreadCrumbs style="margin: 0px 6px 2px 6px" />
          <p style="margin: 2px 6px 2px 2px">
            {{ currentPage }}
          </p>
        </div>
      </v-col>
      <v-col cols="12" class="pt-2">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IconArrowCreadCrumbs from '@/assets/icon-arrow-bread-crumbs.vue';
export default {
  name: 'CredentialDesign',
  components: {
    IconArrowCreadCrumbs,
  },
  computed: {
    currentPage() {
      let option = this.$route.name;
      console.log(option);
      if (this.$te('credentialDesign.preset.' + option)) {
        return this.$t('credentialDesign.preset.' + option);
      }
      return null;
    },
  },
  watch: {},
  created() {
    this.debug(`*** ${this.$options.name} ***`);
  },
  mounted() {},
  methods: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.credential-design {
}
</style>
